import type { ResultsFrameParams } from 'common/frame-params';
import type { PolymorphicWindow } from 'common/types/window';
import type { BrowserConfig } from 'static/frames/config/browser-config';
import { getBrowserConfig } from 'static/frames/config/browser-config';
import type { ResultsElementConfig } from 'static/frames/config/element-config';
import { resultsElementConfigSchema } from 'static/frames/config/element-config';
import { getPairKey } from 'static/frames/config/get-pair-key';
import { parseElementDataAttribute } from 'static/frames/config/parse-element-data-attribute';
import type { QsConfig } from 'static/frames/config/qs-config';
import { parseConfigFromQs } from 'static/frames/config/qs-config';
import type { HashConfig } from 'static/hash-config';
import type { Logger } from 'static/logger';
import { fromZodError } from 'zod-validation-error';

type BuilderArgs = {
    window: PolymorphicWindow;
    transactionId: string;
    element: HTMLElement;
    logger: Logger;
    resultKey: number;
    hashConfig: HashConfig;
};

export class ResultsFrameParamsBuilder {
    args: BuilderArgs;
    _elementConfig?: ResultsElementConfig;
    _qsConfig?: Omit<QsConfig, 'ces' | 'cet'>;
    _browserConfig?: BrowserConfig;

    constructor(args: BuilderArgs) {
        this.args = args;
    }

    // Build the parameters object we will use for building the URL to the
    // results iframe.
    getFrameParams(): ResultsFrameParams {
        return {
            accreditations: this.accreditations,
            advertiserDedupe: this.advertiserDedupe,
            debug: this.debug,
            degree: this.degree,
            ep: this.ep,
            fieldOfStudy: this.fieldOfStudy,
            hideViewMorePrograms: this.hideViewMorePrograms,
            major: this.major,
            modality: this.modality,
            pairKey: this.pairKey,
            parentPageDomain: this.parentPageDomain,
            parentPageUrl: this.parentPageUrl,
            placementType: this.placementType,
            programLength: this.programLength,
            publisherSourceCode: this.publisherSourceCode,
            publisherTrackingCode: this.publisherTrackingCode,
            requirements: this.requirements,
            resultKey: this.resultKey,
            resultsLoadRange: this.resultsLoadRange,
            resultsPerPage: this.resultsPerPage,
            searchId: this.searchId,
            searchOnLoad: this.searchOnLoad,
            showPrivacyPolicy: this.showPrivacyPolicy,
            test: this.test,
            themeId: this.themeId,
            transactionId: this.transactionId,
            zipCode: this.zipCode,
        };
    }

    get accreditations(): ResultsFrameParams['accreditations'] {
        if (this.noFilter) return undefined;
        if (this.qsConfig.ce_accreditations)
            return this.qsConfig.ce_accreditations;
        return this.elementConfig.accreditations;
    }

    get advertiserDedupe(): ResultsFrameParams['advertiserDedupe'] {
        return (
            this.qsConfig.ce_advertiserDedupe ??
            this.elementConfig.advertiserDedupe
        );
    }

    get debug(): ResultsFrameParams['debug'] {
        return this.hashConfig.debug;
    }

    get degree(): ResultsFrameParams['degree'] {
        if (this.noFilter) return undefined;
        return this.qsConfig.ce_degree ?? this.elementConfig.degree;
    }

    get ep(): ResultsFrameParams['ep'] {
        if (this.hashConfig.mockApi) return 'MOCK';
        return this.qsConfig.ce_ep;
    }

    get fieldOfStudy(): ResultsFrameParams['fieldOfStudy'] {
        if (this.noFilter) return undefined;
        return this.qsConfig.ce_fieldOfStudy ?? this.elementConfig.fieldOfStudy;
    }

    get hideViewMorePrograms(): ResultsFrameParams['hideViewMorePrograms'] {
        return this.elementConfig.hideViewMorePrograms;
    }

    get major(): ResultsFrameParams['major'] {
        if (this.noFilter) return undefined;
        return this.qsConfig.ce_major ?? this.elementConfig.major;
    }

    get modality(): ResultsFrameParams['modality'] {
        if (this.noFilter) return undefined;
        return this.qsConfig.ce_modality ?? this.elementConfig.modality;
    }

    get pairKey(): ResultsFrameParams['pairKey'] {
        return getPairKey({
            pairKey: this.elementConfig.pairKey,
            logger: this.args.logger,
        });
    }

    get parentPageDomain(): ResultsFrameParams['parentPageDomain'] {
        return this.browserConfig.parentPageDomain;
    }

    get parentPageUrl(): ResultsFrameParams['parentPageUrl'] {
        return this.browserConfig.parentPageUrl;
    }

    get placementType(): ResultsFrameParams['placementType'] {
        if (this.noFilter) return undefined;
        return (
            this.qsConfig.ce_placementType ?? this.elementConfig.placementType
        );
    }

    get programLength(): ResultsFrameParams['programLength'] {
        if (this.noFilter) return undefined;
        return (
            this.qsConfig.ce_programLength ?? this.elementConfig.programLength
        );
    }

    get publisherSourceCode(): ResultsFrameParams['publisherSourceCode'] {
        return (
            this.qsConfig.ce_publisherSourceCode ??
            this.elementConfig.publisherSourceCode
        );
    }

    get publisherTrackingCode(): ResultsFrameParams['publisherTrackingCode'] {
        return (
            this.qsConfig.ce_publisherTrackingCode ??
            this.elementConfig.publisherTrackingCode
        );
    }

    get requirements(): ResultsFrameParams['requirements'] {
        if (this.noFilter) return undefined;
        return this.qsConfig.ce_requirements ?? this.elementConfig.requirements;
    }

    get resultKey(): ResultsFrameParams['resultKey'] {
        return this.args.resultKey;
    }

    get resultsLoadRange(): ResultsFrameParams['resultsLoadRange'] {
        return this.elementConfig.resultsLoadRange;
    }

    get resultsPerPage(): ResultsFrameParams['resultsPerPage'] {
        return this.elementConfig.resultsPerPage;
    }

    get searchId(): ResultsFrameParams['searchId'] {
        return this.qsConfig.ce_searchId;
    }

    get searchOnLoad(): ResultsFrameParams['searchOnLoad'] {
        return this.elementConfig.searchOnLoad;
    }

    get showPrivacyPolicy(): ResultsFrameParams['showPrivacyPolicy'] {
        return false;
    }

    get test(): ResultsFrameParams['test'] {
        return this.qsConfig.ce_test ?? this.elementConfig.test;
    }

    get themeId(): ResultsFrameParams['themeId'] {
        return this.hashConfig.themeId ?? this.elementConfig.themeId;
    }

    get transactionId(): ResultsFrameParams['transactionId'] {
        return this.args.transactionId;
    }

    get zipCode(): ResultsFrameParams['zipCode'] {
        return (
            this.hashConfig.zip ??
            this.qsConfig.ce_archeruserzip ??
            this.elementConfig.archeruserzip
        );
    }

    // Get config vars defined in the element's "archerConfig" data attribute
    private get elementConfig() {
        if (!this._elementConfig) {
            this._elementConfig = this.parseElementParams();
        }
        return this._elementConfig;
    }

    private get noFilter() {
        return this.hashConfig.noFilter;
    }

    // Get config vars defined in the page's querystring
    private get qsConfig() {
        if (!this._qsConfig) {
            this._qsConfig = parseConfigFromQs({
                window: this.args.window,
                logger: this.args.logger,
            });
        }
        return this._qsConfig;
    }

    // Get config vars from browser environment
    private get browserConfig() {
        if (!this._browserConfig) {
            this._browserConfig = getBrowserConfig({
                window: this.args.window,
            });
        }
        return this._browserConfig;
    }

    // Get config vars from hash args in the URL
    private get hashConfig() {
        return this.args.hashConfig;
    }

    // Parse and validate the element's config object in its "archerConfig" data
    // attribute
    private parseElementParams(): ResultsElementConfig {
        const elementConfig = parseElementDataAttribute(this.args.element);
        const parseResult = resultsElementConfigSchema.safeParse(elementConfig);
        if (parseResult.success) return parseResult.data;
        const msg = fromZodError(parseResult.error);
        throw new Error(`Invalid archerConfig results config: ${msg}`);
    }
}
